"use client";

/**
 * This file sets up the Azure Insights provider for frontend logging. Read documentation here:
 * https://learn.microsoft.com/en-us/azure/azure-monitor/app/javascript-framework-extensions?tabs=react
 */

import type { ReactNode } from "react";
import { AppInsightsContext } from "@microsoft/applicationinsights-react-js";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";
import { ReactPlugin } from "@microsoft/applicationinsights-react-js";
import { ClickAnalyticsPlugin } from "@microsoft/applicationinsights-clickanalytics-js";
import { createBrowserHistory } from "history";
import { useSession } from "next-auth/react";

export default function AzureInsightsProvider(props: {
  connectionString?: string;
  children: ReactNode;
}) {
  // Get session
  const session = useSession();

  // If no connection string, just return children
  if (!props.connectionString) return props.children;

  // If connection string, set up Azure Insights
  const browserHistory = createBrowserHistory();
  const reactPlugin = new ReactPlugin();
  const clickPluginInstance = new ClickAnalyticsPlugin();
  const clickPluginConfig = { autoCapture: true };
  const appInsights = new ApplicationInsights({
    config: {
      connectionString: props.connectionString,
      accountId: session.data?.user.email || undefined,
      extensions: [reactPlugin, clickPluginInstance],
      extensionConfig: {
        [reactPlugin.identifier]: { history: browserHistory },
        [clickPluginInstance.identifier]: clickPluginConfig,
      },
    },
  });
  appInsights.setAuthenticatedUserContext(
    session.data?.user.email || "<unknown>",
  );
  appInsights.loadAppInsights();
  appInsights.trackPageView();

  return (
    <AppInsightsContext.Provider value={reactPlugin}>
      {props.children}
    </AppInsightsContext.Provider>
  );
}
