"use client";
/**
 * This is the client-side entrypoint for your tRPC API. It is used to create the `api` object which
 * contains the Next.js App-wrapper, as well as your type-safe React Query hooks.
 *
 * We also create a few inference helpers for input and output types.
 */
import { api, getQueryClient, getTrpcLinks } from "@shared-server/api";
import type { AppRouter } from "@shared-server/api/app-router";
import { QueryClientProvider } from "@tanstack/react-query";
import type { inferRouterInputs, inferRouterOutputs } from "@trpc/server";
import { useState } from "react";
import superjson from "superjson";

interface TrpcProviderProps {
  children: React.ReactNode;
}

export const TrpcProvider = (props: TrpcProviderProps) => {
  const [queryClient] = useState(() => getQueryClient());
  const [trpcClient] = useState(() =>
    api.createClient({
      links: getTrpcLinks("/api/trpc"),
      transformer: superjson,
    }),
  );
  return (
    <api.Provider client={trpcClient} queryClient={queryClient}>
      <QueryClientProvider client={queryClient}>
        {props.children}
      </QueryClientProvider>
    </api.Provider>
  );
};

/**
 * Inference helper for inputs.
 *
 * @example type HelloInput = RouterInputs['example']['hello']
 */
export type RouterInputs = inferRouterInputs<AppRouter>;

/**
 * Inference helper for outputs.
 *
 * @example type HelloOutput = RouterOutputs['example']['hello']
 */
export type RouterOutputs = inferRouterOutputs<AppRouter>;
