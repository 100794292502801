"use client";

import { AboutContent } from "@shared-components/global/about-content";
import { Button } from "@shared-components/shadcn/button";
import { Card, CardFooter } from "@shared-components/shadcn/card";
import { SessionProvider, signIn, useSession } from "next-auth/react";
import type { ReactNode } from "react";

interface AuthPromptProviderProps {
  children: ReactNode;
}

const LoginProvider = (props: AuthPromptProviderProps) => {
  const { status } = useSession();

  // Render output
  switch (status) {
    case "authenticated":
      return <>{props.children}</>;
    case "unauthenticated":
      return (
        <div className="-mt-14 flex h-full w-full items-center justify-center">
          <Card className="min-w-[350px]">
            <AboutContent />
            <CardFooter>
              <Button
                className="w-full"
                onClick={() => void signIn("azure-ad", { redirect: true })}
              >
                {"Sign in"}
              </Button>
            </CardFooter>
          </Card>
        </div>
      );
    default:
      return null;
  }
};

export default function AuthPromptProvider({
  children,
}: {
  children: ReactNode;
}) {
  return (
    <SessionProvider refetchInterval={60 * 5} refetchOnWindowFocus={true}>
      <LoginProvider>{children}</LoginProvider>
    </SessionProvider>
  );
}
